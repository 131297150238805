
    import {computed, defineComponent, reactive, watch} from 'vue';
    import {campaignStore} from "@/store/campaigns";
    import router from "@/router";
    import {BaseAction, BaseTable, DeleteAction, Pagination, TextColumn} from "@/types/table";
    import {useConfirm} from "@/plugins/confirm";
    import {useTranslation} from "@/plugins/i18n";
    import {Campaign} from "@/interfaces/campaign";
    import CampaignStateBadge from "@/pages/campaigns/CampaignStateBadge.vue";
    import { usePromise } from '@/components/usePromise';
    import { useAxios } from '@/plugins/axios';

    export default defineComponent({
        name: "Campaigns",
        components: {
            CampaignStateBadge
        },
        setup() {

            //  Confirmation plugin
            const confirm = useConfirm();

            //  Translation plugin
            const i18n = useTranslation();

            //  Axios plugin
            const axios = useAxios();

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.Numbers))

            //  Add table columns
            table.addColumn(new TextColumn('name', 'campaign.name'))
            table.addColumn(new TextColumn('reason', 'campaign.goal').hideMobile())
            table.addColumn(new TextColumn('state', 'campaign.status').hideMobile())

            //  Define table row actions
            table.addAction(new BaseAction('table.action.edit').onClick((record: object) => {
                    router.push({name: 'edit-campaign', params: {id: (record as Campaign).id}});
                }).invisible((record: Campaign) => {
                    return record.state !== 'concept';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-pause')
                .onClick((record: Campaign) => {
                  axios.put(`campaign/${record.id}/pause`).then(() => fetchCampaigns(campaigns.value.page)).catch(() => {});
                }).invisible((record: Campaign) => {
                  return record.state !== 'running';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-play')
                .onClick((record: Campaign) => {
                  axios.put(`campaign/${record.id}/resume`).then(() => fetchCampaigns(campaigns.value.page)).catch(() => {});
                }).invisible((record: Campaign) => {
                  return record.state !== 'paused';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-file-search')
                .onClick((record: Campaign) => {
                  router.push({name: 'detail-campaign', params: {id: record.id}});
                }).invisible((record: Campaign) => {
                  return record.state === 'concept';
                })
            ).addAction(new DeleteAction('table.action.delete').onClick((record: Campaign) => {
                    confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description')).then(() => {
                        campaignStore.loading(true);
                        axios.delete(`campaign/${record.id}`)
                            .then(() => campaignStore.fetch(1))
                            .catch(() => campaignStore.fetch(1))
                    });
                }).invisible((record: Campaign) => {
                    return record.state !== 'concept';
                })
            );

            /**
             * Information modal
             */
            const informationModel = reactive(new usePromise());
            const showInformationModal = async () => {
                const confirmed = await informationModel.show();
                if (confirmed) {
                    informationModel.hide();
                }
            }

            const start = () => informationModel.hide();

            const campaigns = computed(() => campaignStore.getState());

            const fetchCampaigns = (page: number) => {
                campaignStore.fetch(page).then(() => {
                    //  If user is on first page and there are no campaigns, show information modal
                    if(page === 1 && campaigns.value.results === 0 && !informationModel.visible) {
                        setTimeout(() => showInformationModal(), 500);
                    }
                }).catch(() => {})
            }

            //  Fetch first campaign page
            fetchCampaigns(1);

            //  On table page changed, fetch another result page
            table.onChangedPage((page: number) => fetchCampaigns(page));

            //  Watch campaigns changes
            watch(() => campaigns, (campaigns) => {
                const campaign = campaigns.value;
                table.setResultsPerPage(campaign.items);
                table.setResults(campaign.results);
                table.loading = campaign.loading;
            }, {immediate: true, deep: true});

            return {
                table,
                router,
                campaigns,
                informationModel,
                start
            }
        }
    })
