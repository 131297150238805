<template>
  <div class="pb-10">
    <div class="bg-custom-100 pt-10 pb-24">
      <div class="max-w-7xl mx-auto px-6 lg:px-8">
        <Header name="Welcome to Brand Ambassador!" :subtitle="`Welcome ${username}`">
          <div class="relative">
            <div class="inline-flex shadow-sm rounded-md divide-x divide-custom-600"
                 @click.prevent="showMenu"
                 @mouseleave="hideMenu">
              <button type="button"
                      class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-custom-600 group focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-custom-500">
                <div
                    class="relative inline-flex items-center bg-custom-500 pl-3 pr-2 border border-transparent rounded-md shadow-sm text-white">
                  <span class=" py-2"><i class="far fa-plus"></i></span>
                  <p class="mx-2.5 text-sm font-medium">
                    Create campaign
                  </p>
                  <span class="p-2 pl-3.5 border-l border-custom-600"><i class="far fa-angle-down"></i></span>
                </div>
              </button>
            </div>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
              <div
                  @mouseenter="showMenu"
                  @mouseleave="hideMenu"
                  v-show="showCreateCampaignMenu"
                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                  <a href="#"
                     @click.prevent="createCampaignWithType(type)"
                     v-for="(type, index) in campaignTypes"
                     :key="`campaign_${index}`"
                     class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabindex="-1"
                     id="menu-item-0">{{ $translate(`campaign.reasons.${type}`) }}</a>
                </div>
              </div>
            </transition>
          </div>
        </Header>
      </div>
    </div>
    <div class="py-10 -mt-24">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-3">
            <div class="bg-white shadow sm:rounded-lg overflow-hidden">
              <Campaigns/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, Ref} from 'vue';
import Campaigns from "@/pages/campaigns/Campaigns.vue";
import {userStore} from "@/store/user";
import router from "@/router";
import {appStore} from "@/store/app";

export default defineComponent({
  name: "Welcome",
  components: {
    Campaigns,
  },
  setup() {
    const showCreateCampaignMenu: Ref<boolean> = ref(false);
    const hideCreateCampaignMenu: Ref<number | undefined> = ref(undefined);

    const showMenu = () => {
      if (hideCreateCampaignMenu.value) {
        clearTimeout(hideCreateCampaignMenu.value);
      }
      showCreateCampaignMenu.value = true;
    }
    const hideMenu = () => {
      hideCreateCampaignMenu.value = setTimeout(() => {
        showCreateCampaignMenu.value = false;
      }, 250)
    }

    const createCampaignWithType = (type: string) => {
      hideMenu();
      router.push({name: 'create-campaign', query: {type}});
    }

    return {
      campaignTypes: appStore.getState().campaigns,
      showCreateCampaignMenu,
      showMenu,
      hideMenu,
      username: userStore.getState().name,
      createCampaignWithType,
    }
  }
})
</script>