<template>
    <CardHeader :name="$translate('campaign.overview.title')" :description="$translate('campaign.description')"/>
    <DataTable :table="table" :dataset="campaigns.campaigns" empty="campaign.no-results">
        <template #column_reason="{value}">
            {{ $translate(`campaign.reasons.${value}`) }}
        </template>
        <template #column_state="{value}">
            <CampaignStateBadge :state="value" />
        </template>
        <template #image>
            <img src="../../assets/images/launch.svg" class="h-32 m-auto mt-16 mb-8"/>
        </template>
        <template #empty>
            <span class="inline-flex rounded-md shadow-sm">
                <button type="button" @click.prevent="router.push({name:'create-campaign'})"
                        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    {{ $translate('campaign.launch-first') }}
                </button>
            </span>
        </template>
    </DataTable>
    <Modal :modal="informationModel">
        <div class="block w-full">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Welcome to BrandAmbassador!
                </h3>
                <div class="mt-2 text-sm text-gray-500 prose">
                    <p>
                        BrandAmbassador is the platform where we connect brands with nano-influencers and their followers through giveaway campaigns. Giveaways are a great way to create engagement and excitement around your product.
                    </p>
                    <p>
                        When an influencer hosts a giveaway for you, they will receive your product so they can try it out themselves. They will share their experience with your product, so they can inspire their followers to participate in the giveaway.
                    </p>
                    <p>
                        A giveaway means that the followers of the influencer will complete social actions, to spread the word about your product. To stimulate the visibility of your product, only the most engaged followers will get the opportunity to win.
                    </p>
                    <p><strong>Here's how to get started:</strong></p>
                    <ul>
                        <li>Create your first giveaway campaign</li>
                        <li>During the set-up of your first campaign, you will be asked to fill in your company details and add your social media channels</li>
                        <li>Select your product, add your brand and select your e-commerce channel</li>
                        <li>Complete by adding your payment details, and you're good to go!</li>
                    </ul>
                    <p>
                        More than one brand? When you create your next campaign, you can easily add another brand!
                    </p>
                </div>
            </div>
        </div>
        <template #footer>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button type="button"
                            @click.prevent="start"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                        Let’s get started!
                    </button>
                </span>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, reactive, watch} from 'vue';
    import {campaignStore} from "@/store/campaigns";
    import router from "@/router";
    import {BaseAction, BaseTable, DeleteAction, Pagination, TextColumn} from "@/types/table";
    import {useConfirm} from "@/plugins/confirm";
    import {useTranslation} from "@/plugins/i18n";
    import {Campaign} from "@/interfaces/campaign";
    import CampaignStateBadge from "@/pages/campaigns/CampaignStateBadge.vue";
    import { usePromise } from '@/components/usePromise';
    import { useAxios } from '@/plugins/axios';

    export default defineComponent({
        name: "Campaigns",
        components: {
            CampaignStateBadge
        },
        setup() {

            //  Confirmation plugin
            const confirm = useConfirm();

            //  Translation plugin
            const i18n = useTranslation();

            //  Axios plugin
            const axios = useAxios();

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.Numbers))

            //  Add table columns
            table.addColumn(new TextColumn('name', 'campaign.name'))
            table.addColumn(new TextColumn('reason', 'campaign.goal').hideMobile())
            table.addColumn(new TextColumn('state', 'campaign.status').hideMobile())

            //  Define table row actions
            table.addAction(new BaseAction('table.action.edit').onClick((record: object) => {
                    router.push({name: 'edit-campaign', params: {id: (record as Campaign).id}});
                }).invisible((record: Campaign) => {
                    return record.state !== 'concept';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-pause')
                .onClick((record: Campaign) => {
                  axios.put(`campaign/${record.id}/pause`).then(() => fetchCampaigns(campaigns.value.page)).catch(() => {});
                }).invisible((record: Campaign) => {
                  return record.state !== 'running';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-play')
                .onClick((record: Campaign) => {
                  axios.put(`campaign/${record.id}/resume`).then(() => fetchCampaigns(campaigns.value.page)).catch(() => {});
                }).invisible((record: Campaign) => {
                  return record.state !== 'paused';
                })
            ).addAction(new BaseAction('table.action.view').setIcon('far fa-file-search')
                .onClick((record: Campaign) => {
                  router.push({name: 'detail-campaign', params: {id: record.id}});
                }).invisible((record: Campaign) => {
                  return record.state === 'concept';
                })
            ).addAction(new DeleteAction('table.action.delete').onClick((record: Campaign) => {
                    confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description')).then(() => {
                        campaignStore.loading(true);
                        axios.delete(`campaign/${record.id}`)
                            .then(() => campaignStore.fetch(1))
                            .catch(() => campaignStore.fetch(1))
                    });
                }).invisible((record: Campaign) => {
                    return record.state !== 'concept';
                })
            );

            /**
             * Information modal
             */
            const informationModel = reactive(new usePromise());
            const showInformationModal = async () => {
                const confirmed = await informationModel.show();
                if (confirmed) {
                    informationModel.hide();
                }
            }

            const start = () => informationModel.hide();

            const campaigns = computed(() => campaignStore.getState());

            const fetchCampaigns = (page: number) => {
                campaignStore.fetch(page).then(() => {
                    //  If user is on first page and there are no campaigns, show information modal
                    if(page === 1 && campaigns.value.results === 0 && !informationModel.visible) {
                        setTimeout(() => showInformationModal(), 500);
                    }
                }).catch(() => {})
            }

            //  Fetch first campaign page
            fetchCampaigns(1);

            //  On table page changed, fetch another result page
            table.onChangedPage((page: number) => fetchCampaigns(page));

            //  Watch campaigns changes
            watch(() => campaigns, (campaigns) => {
                const campaign = campaigns.value;
                table.setResultsPerPage(campaign.items);
                table.setResults(campaign.results);
                table.loading = campaign.loading;
            }, {immediate: true, deep: true});

            return {
                table,
                router,
                campaigns,
                informationModel,
                start
            }
        }
    })
</script>