import ls from 'local-storage';
import {Store} from './store';
import {reactive, readonly} from 'vue';
import {$axios, getErrorMessage} from "@/plugins/axios";
import {UserModel} from "@/store/user";

export type Campaign = {
    id: string;
    name: string;
    reason: string;
    state: string;
}

export class CampaignModel implements Campaign {
    id: string = '';
    name: string = '';
    reason: string = '';
    state: string = 'concept';
}

export type Campaigns = {
    loading: boolean,
    page: number;
    pages: number;
    results: number;
    items: number;
    campaigns: Campaign[];
}

export class CampaignsModel implements Campaigns {
    loading: boolean = false;
    page: number = 0;
    pages: number = 0;
    results: number = 0;
    items: number = 0;
    campaigns: Campaign[] = [];
}

class CampaignStore extends Store <Campaigns> {
    protected data(): Campaigns {
        return {
            loading: false,
            page: 0,
            pages: 1,
            results: 1,
            items: 10,
            campaigns: []
        };
    }

    constructor() {
        super();
        this.state = reactive(Object.assign(new CampaignsModel, ls('campaigns')));
    }

    loading(loading: boolean): boolean {
        this.state.loading = loading;
        return loading;
    }

    fetch(page: number = 1): Promise<any> {
        this.state.loading = true;
        return new Promise((resolve, reject) => {
            $axios.get(`campaigns`, {params: {page}})
                .then(response => response.data)
                .then(response => {
                    this.state.loading = false;
                    this.state.campaigns = [];
                    response.data.forEach((campaign: any) => {
                        this.state.campaigns.push(Object.assign(new CampaignModel(), campaign));
                    })
                    this.state.page = page;
                    this.state.items = response.meta.per_page
                    this.state.results = response.meta.total;
                    this.state.pages = Math.ceil(this.state.results / this.state.items);
                    this.saveState();
                    resolve();
                })
                .catch(() => reject());
        });
    }

    saveState() {
        ls('campaigns', this.state);
    }

}

export const campaignStore: CampaignStore = new CampaignStore();
