
import {defineComponent, ref, Ref} from 'vue';
import Campaigns from "@/pages/campaigns/Campaigns.vue";
import {userStore} from "@/store/user";
import router from "@/router";
import {appStore} from "@/store/app";

export default defineComponent({
  name: "Welcome",
  components: {
    Campaigns,
  },
  setup() {
    const showCreateCampaignMenu: Ref<boolean> = ref(false);
    const hideCreateCampaignMenu: Ref<number | undefined> = ref(undefined);

    const showMenu = () => {
      if (hideCreateCampaignMenu.value) {
        clearTimeout(hideCreateCampaignMenu.value);
      }
      showCreateCampaignMenu.value = true;
    }
    const hideMenu = () => {
      hideCreateCampaignMenu.value = setTimeout(() => {
        showCreateCampaignMenu.value = false;
      }, 250)
    }

    const createCampaignWithType = (type: string) => {
      hideMenu();
      router.push({name: 'create-campaign', query: {type}});
    }

    return {
      campaignTypes: appStore.getState().campaigns,
      showCreateCampaignMenu,
      showMenu,
      hideMenu,
      username: userStore.getState().name,
      createCampaignWithType,
    }
  }
})
